import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import Cookies from "universal-cookie";
import { MdEmail } from "react-icons/md";
import { cmsLogin, cmsLoginVerify } from "../../Services/Auth";
import toast from "react-hot-toast";

const cookies = new Cookies();

export const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [otp, setOtp] = useState("");
  const { dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const [timerMsg, setTimerMsg] = useState(false);
  const [errorOTP, setErrorOTP] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setEmailError('');
  };

  const CountDown = () => {
    setTimer(60);
    setShowResend(false);
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setShowResend(true);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }

  const getOTP = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (!formData.email) {
      setEmailError('Email is required!')
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      setEmailError('Email is invalid')
    } else {
      setLoading(true);
      setIsLoading(true);
      try {
        const data = await cmsLogin(formData);
        setTimerMsg(true);
        toast.success(`${data.message}`);
        setLoading(false);
        CountDown();
      } catch (error) {
        setLoading(false);
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          console.log("Bad Request Error: ", error.response.data);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setErrorOTP('Verification code Required');
    } else if (otp.length < 5) {
      setErrorOTP('OTP should be 5 number');
    } else {
      setIsLoadingVerify(true);
      try {
        const data = await cmsLoginVerify(formData, otp);
        toast.success(`You are successfully loged in.`);
        setIsLoadingVerify(false);
        cookies.set("jwtToken", data.accessToken, { path: "/" });
        dispatch({ type: "USER", payload: true });
        navigate('/dashboard/home')
        setIsLoadingVerify(false);
      } catch (error) {
        setIsLoadingVerify(false);
        console.error("OTP verification error:", error);
      }
    }
  };

  return (
    <>
      <div className="container mx-auto mt-36 mb-20 font-avenir ">
        <div className="md:py-5 rounded-3xl">
          <div className="md:grid md:grid-cols-2 py-5 rounded-3xl">
            <div className='text-center'>
              <h1 className='text-3xl font-semibold text-bgclr'>Streamlined Digital Communications,</h1>
              <h1 className='text-3xl font-semibold text-bgclr'>Seamless Compliance</h1>
              <div className='flex justify-center mt-3'>
                <img src="https://cdn.clevdoc.com/assets/Login/login-img.png" alt="" width="50%" />
              </div>
            </div>
            <div className="md:mx-10 mx-3 mt-10 md:mt-0">
              <h1 className='text-3xl font-semibold text-center md:text-left text-bgclr mb-4'>Welcome!</h1>
              <h1 className='text-lg font-bold text-bgclr mb-2'>Login</h1>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div>
                  <div className='grid grid-cols-9 mt-5 gap-2'>
                    {/* Email ID */}
                    <div className="col-span-7">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-txtclr2 mb-1"
                      >
                        Email ID<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="pl-10 input-style"
                        placeholder="Email ID"
                        disabled={isLoading}
                      />
                      <MdEmail
                        className="absolute -mt-7 ml-4 text-gray-400
                    flex items-center  
                    pointer-events-none"
                      />
                    </div>

                    <div className='flex justify-center col-span-2 mt-6'>
                      <button
                        onClick={getOTP}
                        className="w-full text-center bg-bgclr hover:bg-bgclr6 text-txtclr font-semibold rounded-md focus:outline-none focus:shadow-outline"
                        disabled={isLoading}
                      >
                        {loading ? 'Sending' : 'Get OTP'}
                      </button>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 h-9'>
                    <div>
                      {emailError && <span className='text-red-600 font-semibold'>{emailError}</span>}
                    </div>

                    {timerMsg && (
                      <div className="flex justify-end mr-24">
                        {timer > 0 && (
                          <div className="text-gray-700 italic">
                            Resend OTP in <span className="text-green-800 font-semibold"> {" "}
                              {Math.floor(timer / 60)}:
                              {timer % 60 < 10
                                ? `0${timer % 60}`
                                : timer % 60}
                            </span>
                          </div>
                        )}
                        {showResend && (
                          <div>
                            <button
                              className="ml-2 text-txtclr3 underline italic"
                              onClick={(e) => getOTP(e)}
                            >
                              Resend OTP
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-txtclr2 mb-1"
                    >
                      Verification Code<span className='text-red-600'>*</span>
                    </label>
                    <input
                      type="password"
                      id="otp"
                      name="otp"
                      maxLength="5"
                      value={otp}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      onChange={(e) => {
                        setOtp(e.target.value)
                        setErrorOTP('')
                      }}
                      className="input-style"
                      placeholder="Enter your verification Code"
                      disabled={!timerMsg}
                    />
                    {errorOTP && <span className='text-red-600 font-semibold'>{errorOTP}</span>}
                  </div>

                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      className="mt-5 bg-bgclr w-full hover:bg-bg-btnhover text-txtclr px-4 py-2 rounded"
                      disabled={!timerMsg || isLoadingVerify}
                    >
                      {isLoadingVerify ? 'Logging in...' : 'Log In'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
